import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import { Container, Row, Col } from 'reactstrap'
import styled from 'styled-components'

import Layout from '../components/Layout'
import Main from '../components/layouts/Main'
import { HTMLContent } from '../components/Content'
import scssVars from '../components/scss-variables'
import FeaturedServices from '../components/RepairServices/FeaturedServices'
import ReadyToStart from '../components/Shared/ReadyToStart'
import { Breadcrumbs } from '../components/Services/Intro'
import SEO from '../components/seo'

const Heading = styled.h1`
  font-weight: 200;
  color: ${scssVars.darkBrown};
  font-size: 24px;
  display: flex;
  line-height: 1.25;
  align-items: center;
  justify-content: center;
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
    font-size: 44px;
    flex-direction: row;
    text-align: left;
  }
`

const HeadingLine = styled.span`
  border: 1px solid ${scssVars.brown};
  height: 1px;
  margin: 0 auto 40px;
  width: 25%;
  display: block;
  margin: 25px auto;
  height: 1px;
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    margin-left: 30px;
    margin-bottom: 0;
    flex-grow: 1;
  }
`

const Breadbox = styled.div`
  padding: 20px 0 10px 0;
  ${Breadcrumbs} {
    margin-left: 0;
    li {
      font-family: 'Montserrat', san-serif;
    }
    a {
      color: ${scssVars.darkBrown};
      text-decoration: none;
      &:hover, &:focus {
        text-decoration: underline;
      }
    }
  }
`

const StyledHTMLContent = styled(HTMLContent)`
  h1 {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 800;
    color: ${scssVars.darkBrown};
    line-height: 1.375;
  }
  p {
    &:first-child {
      font-family: "Montserrat", sans-serif;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.375;
      color: ${scssVars.orange};
    }
    font-size: 18px;
    line-height: 1.33;
    color: ${scssVars.darkBrown};
    margin-bottom: 1.5rem;
  }
  a {
    font-family: 'EB Garamond', serif;
    color: ${scssVars.darkBrown};
    text-decoration: underline;
  }
  img {
    width: 100%;
  }
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    h1 {
      line-height: 1.5;
    }
    p {
      &:first-child {
        font-size: 20px;
        line-height: 1.4;
        a {
          font-family: inherit;
          color: ${scssVars.orange};
        }
      }
    }
  }
`

const TipContainer = styled.div`
  position: relative;
  background-color: ${scssVars.gray};
  color: ${scssVars.darkBrown};
  margin-left: 3rem;
  h1 {
    margin-left: -3rem;
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.42;
    padding-top: 1.5rem;
    margin-bottom: 0;
  }
  p {
    font-size: 18px;
    line-height: 1.33;
    letter-spacing: 0.05em;
    padding: 0.5rem 1rem 1.5rem 1rem;
  }
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    top: 1rem;
    .img-wrapper {
      margin-left: unset;
      position: relative;
      right: 3rem;
      top: -1rem;
      width: 100%;
      max-width: 580px;
      border: 1px solid ${scssVars.orange};
      img {
        width: 100%;
      }
    }
    p {
      padding: 0.5rem 2rem 2.5rem 2rem;
    }
  }
`

const ExpertTips = ({ src, tip }) => (
  <TipContainer className="my-5 my-lg-0">
    {src && (
      <div className="d-none d-lg-block img-wrapper">
        <img src={src} alt="test" />
      </div>
    )}
    {tip && (
      <>
        <h1>
          Tips from
          <br />
          the Experts
        </h1>
        <p>{tip}</p>
      </>
    )}
  </TipContainer>
)

ExpertTips.propTypes = {
  src: PropTypes.string,
  tip: PropTypes.string
}

const RepairServiceTemplate = ({
  content, title, src, mensDetailSrc, otherDetailSrc, srcMobile, mobileMensIcon, otherMobileIcon, tip, path, url
}) => {
  const imgType = (url) => {
    const urlPath = url.state ? (url.state.prevPage || url.pathname) : url.pathname

    if (urlPath.includes('womens')) {
      return [src, srcMobile]
    }

    if (urlPath.includes('mens')) {
      if (mensDetailSrc) {
        return [mensDetailSrc, mobileMensIcon]
      } else {
        return [src, srcMobile]
      }
    }

    if (!urlPath.includes('shoes') && !urlPath.includes('boots')) {
      if (otherDetailSrc) {
        return [otherDetailSrc, otherMobileIcon]
      } else {
        return [src, srcMobile]
      }
    }
  }
  return (
    <Main>
      <SEO title={title} pathname={path} description="Services we offer for all your leather repair and care needs" />
      <div className="d-lg-none">
        <Row className="bg-clr-light-gray">
          <Col xs={4}>
            <img className="w-100" src={imgType(url)[1]} alt={title} />
          </Col>
          <Col xs={8} className="d-flex text-left">
            <Heading>{title}</Heading>
          </Col>
        </Row>
        <Container>
          <div className="py-3">
            <Link className="text-clr-sambuca" to="/repair-services">
              &laquo; Repair Services
            </Link>
          </div>
          <StyledHTMLContent content={content} />
          <ExpertTips tip={tip} />
        </Container>
      </div>
      <div className="d-none d-lg-block">
        <Container style={{ maxWidth: '1280px' }} fluid>
          <Breadbox>
            <Breadcrumbs>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/repair-services">
                  Repair Services
                </Link>
              </li>
              <li>{title}</li>
            </Breadcrumbs>
          </Breadbox>
          <Heading>
            {title}
            <HeadingLine />
          </Heading>
          <Row className="mt-5">
            <Col lg="6">
              <ExpertTips src={imgType(url)[0]} tip={tip} />
            </Col>
            <Col className="px-5" lg="6">
              <StyledHTMLContent content={content} />
            </Col>
          </Row>
        </Container>
      </div>
      <ReadyToStart />
      <FeaturedServices />
    </Main>
  )
}

RepairServiceTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  src: PropTypes.string,
  mensDetailSrc: PropTypes.string,
  otherDetailSrc: PropTypes.string,
  srcMobile: PropTypes.string,
  mobileMensIcon: PropTypes.string,
  otherMobileIcon: PropTypes.string,
  path: PropTypes.string.isRequired,
  tip: PropTypes.string,
  relatedItems: PropTypes.array.isRequired,
  url: PropTypes.object
}

export default function RepairServicePage ({ data, location }) {
  const url = location || ''
  const { markdownRemark: post } = data
  return (
    <Layout>
      <RepairServiceTemplate
        title={post.frontmatter.title}
        content={post.html}
        src={post.frontmatter.src}
        mensDetailSrc={post.frontmatter.mensDetailSrc}
        otherDetailSrc={post.frontmatter.otherDetailSrc}
        srcMobile={post.frontmatter.srcMobile}
        mobileMensIcon={post.frontmatter.mobileMensIcon}
        otherMobileIcon={post.frontmatter.otherMobileIcon}
        tip={post.frontmatter.tip}
        path={post.frontmatter.path}
        relatedItems={post.frontmatter.relatedItems}
        url={url}
      />
    </Layout>
  )
}

RepairServicePage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object
}

export const repairServicePageQuery = graphql`
  query RepairServicePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        path
        src
        mensDetailSrc
        otherDetailSrc
        tip
        srcMobile
        mobileMensIcon
        otherMobileIcon
        relatedItems
      }
    }
  }
`
